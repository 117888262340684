<template>
  <menu-skeleton title="Workflows">
    <div
      class="lg:mt-0 bg-white md:h-full mx-4 my-4 px-4 py-8 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded"
    >
      <div class="flex justify-end mb-4">
        <router-link
          to="/templates/workflows/create"
          class="green-btn"
        >
          New workflow
        </router-link>
      </div>

      <v-client-table
        v-if="!loading"
        ref="datatable"
        :data="workflows"
        :columns="columns"
        :options="options"
        @row-click="goToDetail"
        @pagination="onPagination"
      >
        <div
          slot="cta"
          slot-scope="props"
        >
          <img
            v-tooltip="{ ...tooltip, content: 'Duplicate' }"
            class="absolute pin-r pin-t z-10 mr-10"
            src="@/assets/img/icons/ico-duplicate-grey.svg"
            alt="Delete"
            width="15"
            @click="handleDuplicate($event, props.row.id)"
          >
          <img
            v-if="props.row.type !== 'default'"
            v-tooltip="{ ...tooltip, content: 'Delete' }"
            class="absolute pin-r pin-t z-10 mr-2"
            src="@/assets/img/icons/delete.svg"
            alt="Delete"
            width="17"
            @click="handleDelete($event, props.row.id)"
          >
        </div>
      </v-client-table>
    </div>
  </menu-skeleton>
</template>

<script>
import MenuSkeleton from './Skeletons/MenuSkeleton'
import { deleteWorkflow, duplicateWorkflow, getWorkflows } from '../../api/Workflows'

export default {
  name: 'TemplatesWorkflowList',
  components: { MenuSkeleton },
  data() {
    return {
      page: 1,
      loading: true,
      workflows: [],
      columns: ['name', 'cta'],
      tooltip: {
        delay: 0,
        show: false,
        offset: 5,
        trigger: 'hover',
        classes: ['header-tooltip'],
        placement: 'bottom'
      },
      options: {
        filterable: false,
        headings: {
          name: 'Name',
          cta: '',
        },
        columnsDisplay: {
          created_at: 'min_tabletL',
          subject: 'min_tabletL',
        },
        orderBy: { column:'name', ascending: true },
        sortable: ['name', 'created_at'],
        descOrderColumns: ['date'],
        perPage: 20,
        perPageValues: [20,50,75,100],
      }
    }
  },
  mounted() {
    this.loadWorkflows()
  },
  methods: {
    onPagination(index){
      this.page = index
    },
    prepareWorkflow(workflow) {
      return {
        ...workflow,
        name: workflow.type === 'default'
          ? `${workflow.name} (default)`
          : workflow.name,
      }
    },
    async loadWorkflows() {
      this.loading = true
      const res = this.handleErrors(await getWorkflows())
      if (!res) return

      this.workflows = res.data.data.map(this.prepareWorkflow)
      this.loading = false
    },
    async handleDelete(event, id) {
      event.stopPropagation()

      const confirmed = await this.$alert.confirm({
        title: 'Are you sure you want to delete?',
        text: 'Make sure you have removed this workflow from all of your projects.',
      })

      if (!confirmed) return

      const res = this.handleErrors(await deleteWorkflow(id))
      if (!res) return

      this.loadWorkflows()
      this.$refs.datatable.setPage(this.page)
    },
    async handleDuplicate(event, id) {
      event.stopPropagation()

      const res = this.handleErrors(await duplicateWorkflow(id))

      if (!res) return

      this.loadWorkflows()
      this.$refs.datatable.setPage(this.page)
    },
    goToDetail(data) {
      this.$router.push(`/templates/workflows/${data.row.id}/manage`)
    },
  },
}
</script>

<style scoped>

</style>
