<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav">
    <vue-headful
      :title="`${title} | Octoa`"
    />
    <div class="flex flex-wrap mb-12">
      <div class="w-full lg:w-1/4">
        <template-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuSkeleton',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
